import { useState, useEffect } from "react";
import { TableCell, TableRow, Button, ButtonGroup } from "@mui/material";
import {
  Check as CheckIcon,
  DeleteOutline as DeleteIcon,
  WhatsApp as WhatsAppIcon,
  Laptop as LaptopIcon,
  Edit as EditIcon,
  ThumbDownOffAlt,
} from "@mui/icons-material";
import { WEB_URL } from "../../utils/constants";
import { alpha } from "@mui/material/styles";

export const RsvpListRow = ({
  row,
  sendWhatsAppMobile,
  sendWhatsAppWeb,
  deleteRsvp,
  rejectRsvp,
  handleEdit,
}) => {
  const {
    firstName,
    lastName,
    accepted,
    isActive,
    extraInvites,
    invitees,
    hasChildren,
    rsvpId,
    phone,
    email,
    needHotel,
    beverages,
    openedTimes,
    inviteSent = false,
    rejected = false,
  } = row;
  const [isSent, setIsSent] = useState(inviteSent);
  const [btnSendClass, setBtnSendClass] = useState(
    inviteSent ? {} : { color: "red" }
  );

  useEffect(() => {
    if (isSent) {
      setBtnSendClass({});
    }
  }, [isSent]);

  const setRowBackground = (theme) => {
    let color = null;

    if (accepted) {
      color = theme.palette.success.main;
    }

    if (rejected || !isActive) {
      color = theme.palette.error.main;
    }

    return color ? alpha(color, theme.palette.action.activatedOpacity) : "";
  };

  return (
    <>
      <TableRow
        key={rsvpId}
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          bgcolor: setRowBackground,
        }}
      >
        <TableCell component="th" scope="row">
          {`${openedTimes ?? 0} veces`}
        </TableCell>
        <TableCell component="th" scope="row">
          <div tooltip={rsvpId}>{`${firstName} ${lastName}`}</div>
        </TableCell>
        <TableCell component="th" scope="row">
          {`${invitees?.length ?? 0}/${extraInvites}`}
        </TableCell>
        <TableCell component="th" scope="row">
          {invitees?.join(", ")}
        </TableCell>
        <TableCell component="th" scope="row">
          {phone}
        </TableCell>
        <TableCell component="th" scope="row">
          {email}
        </TableCell>
        <TableCell component="th" scope="row">
          {needHotel ? <CheckIcon sx={{ color: "#0000FF" }} /> : ""}
        </TableCell>
        <TableCell component="th" scope="row">
          {beverages?.join(", ")}
        </TableCell>
        <TableCell component="th" scope="row">
          {hasChildren ? <CheckIcon sx={{ color: "#0000FF" }} /> : ""}
        </TableCell>
        <TableCell component="th" scope="row">
          <ButtonGroup variant="text" aria-label="actions">
            <Button onClick={() => handleEdit({ row })}>
              <EditIcon />
            </Button>
            {isActive && !accepted && (
              <>
                <Button>
                  <DeleteIcon onClick={() => deleteRsvp({ rsvpId })} />
                </Button>
                <Button
                  onClick={() =>
                    sendWhatsAppMobile({ firstName, phone, rsvpId })
                  }
                >
                  <WhatsAppIcon />
                </Button>
                <Button onClick={() => alert(`${WEB_URL}#/?rsvpId=${rsvpId}`)}>
                  Rsvp url
                </Button>
                <Button
                  onClick={() => {
                    console.log("isSent", isSent);
                    setIsSent(true);
                    sendWhatsAppWeb({ firstName, phone, rsvpId });
                  }}
                  style={btnSendClass}
                >
                  <LaptopIcon />
                </Button>
                <Button onClick={() => rejectRsvp({ rsvpId })}>
                  <ThumbDownOffAlt />
                </Button>
                <Button onClick={() => alert(row)}>Log</Button>
              </>
            )}
          </ButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
};
