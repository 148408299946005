import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./styles";

export const NotRsvp = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <Box sx={styles.container} id="rsvp">
          <Box>
            <Typography sx={styles.description}>
              Hola, si estas viendo esto es por que no has sido invitado a
              nuestra boda, si quisieras asistir por favor contacta a Andrea y
              Felipe!
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
