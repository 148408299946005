import { WEB_URL } from "./constants";

export const buildText = ({ firstName, url }) => {
  return `¡Hola ${firstName}!

Si te llega este mensaje, es por que estas invitado a nuestra boda! ⛪👰🏻‍♀️🤵🏻
puedes confirmar tu asistencia en el siguiente link:

${url}

Es importante que confirmes tu asistencia antes de el 1 de Mayo para asegurar tu lugar!!
Esperamos ansiosamente contar con tu presencia, por favor registra a tus invitados para asegurar el lugar de ellos también. Nos vemos en la boda!!

Atte: Felipe&Andy`;
};

export const getUrl = ({ rsvpId }) => {
  return `${WEB_URL}#/?rsvpId=${rsvpId}`;
};

export const sendMobile = ({ firstName, phone, rsvpId }) => {
  const url = getUrl({ rsvpId });
  const message = buildText({ firstName, url });

  const linkToWhatsapp =
    "whatsapp://send?phone=" +
    encodeURIComponent(phone) +
    "&text=" +
    encodeURIComponent(message);
  window.open(linkToWhatsapp);
};

export const sendWeb = ({ firstName, phone, rsvpId }) => {
  const url = getUrl({ rsvpId });
  const message = buildText({ firstName, url });

  const linkToWhatsapp = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
    message
  )}`;

  window.open(linkToWhatsapp, "_blank");
};
